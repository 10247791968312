$lyl-screen-small: (width 1025px 1300px);
$lyl-screen-medium: (width 1301px 1445px);
$lyl-screen-large: (width 1446px 1570px);

//Welcome popup
#colorbox.welcome15-overlay {
  height: 650px !important;
  @include breakpoint($medium-up) {
    height: 550px !important;
  }
  #cboxMiddleRight,
  #cboxMiddLeft,
  #cboxLoadedContent,
  #cboxWrapper {
    height: 650px !important;
    @include breakpoint($medium-up) {
      height: 550px !important;
    }
  }
}

.welcome15-overlay {
  #cboxLoadedContent {
    .welcome-offer-popup-wrapper__subheader {
      font-size: 20px;
      margin-top: 53px;
      font-style: normal;
      @include breakpoint($medium-portrait-only) {
        font-size: 17px;
        margin-top: 0;
      }
    }
  }
}

//Landing Page
.loyalty__market__status__content {
  .loyalty__market__status__section {
    padding: unset;
    height: unset;
    vertical-align: unset;
    line-height: unset;

    p:not(.loyalty__market__status__content__empty) {
      margin: 0 auto;
      padding: 10px 0;
    }
  }
}

.loyalty__market__status__header {
  @include breakpoint($medium-up) {
    display: flex;
    justify-content: space-evenly;
  }
}

.loyalty__market__status__content__field {
  @include breakpoint($medium-up) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }
}

@include breakpoint($small-only) {
  .loyalty__market__status__tier- {
    &1,
    &2,
    &3 {
      .loyalty__market__status__header__logo {
        width: 40px;
        height: 40px;
      }
      .loyalty__market__status__header__title {
        padding: 9.5px 0 0 2px;
        font-size: 15px;
      }
      .loyalty__market__status__header__points {
        padding: 9px 0 0;
        font-size: 13px;
        letter-spacing: -1px;
      }
    }
  }
}

// Max width: 767px
@include breakpoint($small-only) {
  // Landing Page
  .loyalty__market__header__text {
    bottom: 0;
  }
}

@include breakpoint($med-small-up) {
  .loyalty__market__header {
    height: 475px;
  }
}

//Cancellation popup
div.loyalty-cancel-overlay#colorbox {
  @include breakpoint($large-up) {
    top: 10% !important;
    left: 25% !important;
  }
  @include breakpoint($medium-up) {
    width: 835px !important;
    height: 635px !important;
  }
  @include breakpoint($tablet-only) {
    left: 8% !important;
  }
  @include breakpoint($small-down) {
    left: 0 !important;
    width: 97% !important;
    height: 935px !important;
    @media (max-height: 920px) {
      height: 735px !important;
      .loyalty_cancellation_popup {
        padding-top: 50px !important;
        .loyalty--popover__form {
          .loyalty--popover__contact--us {
            margin-top: 35px;
          }
          .loyalty--popover__buttons--retain {
            margin-top: 60px;
          }
        }
      }
    }
  }
  div#cboxWrapper {
    padding: 0;
    @include breakpoint($medium-up) {
      width: 835px !important;
      height: 635px !important;
    }
    @include breakpoint($small-down) {
      left: 0 !important;
      width: 100% !important;
      height: 935px !important;
    }
    div#cboxContent {
      @include breakpoint($medium-up) {
        width: 835px !important;
        height: 635px !important;
      }
      @include breakpoint($small-down) {
        left: 0 !important;
        width: 100% !important;
        height: 935px !important;
      }
      div#cboxLoadedContent {
        @include breakpoint($small-down) {
          padding: 0;
        }
        @include breakpoint($medium-up) {
          padding: 0;
        }
        background-size: 100% 100%;
        background-image: url('/media/export/cms/loyalty/cacellation_popup_banner_mobile.png');
        @include breakpoint($medium-up) {
          background-image: url('/media/export/cms/loyalty/welcome_popup_banner_pc.png');
          width: 835px !important;
          height: 635px !important;
        }
        @include breakpoint($small-down) {
          left: 0 !important;
          width: 100% !important;
          height: 935px !important;
        }
      }
    }
  }
  .loyalty--popover__cancel--checkbox.loyalty--popover__content--container {
    padding: 35px;
    padding-top: 5px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.7;
    @include breakpoint($medium-up) {
      width: 500px;
    }
  }
  .loyalty_cancellation_popup {
    text-align: center;
    color: $white;
    background-size: 100% 100%;
    width: 100%;
    @include breakpoint($small-down) {
      padding-top: 70px;
      margin-left: 10px;
      width: 90%;
    }
    .loyalty--popover__header {
      margin-top: 170px;
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-family: 'La Mer Text', serif;
      p {
        margin: 0 0 10px;
        @include breakpoint($small-down) {
          font-size: 20px;
        }
      }
    }
    .loyalty--popover__sub--header {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 19px;
      font-family: 'La Mer Text', serif;
    }
    .loyalty--popover__contact--us {
      text-align: center;
      margin-top: 75px;
      color: $white;
      @include breakpoint($small-down) {
        width: 100%;
        padding: 5px;
      }
    }
    .loyalty--popover__cancel--option {
      margin: 5px;
      .loyalty--popover__content--checkbox {
        p {
          display: inline;
          text-transform: none;
          font-style: normal;
        }
      }
      .loyalty--popover__cancel--checkbox {
        input[type='checkbox'] ~ label::before {
          height: 15px;
          width: 15px;
        }
      }
    }
    .cancel_loyalty_submit {
      border-radius: 0;
      padding: 20px;
      min-width: 190px;
      margin-top: -140px;
    }
    .loyalty--popover__buttons--retain {
      margin-top: 60px;
      @include breakpoint($small-down) {
        margin-top: 90px;
      }
      color: $white;
    }
    .loyalty--popover__form {
      text-transform: none;
    }
  }
}

// Account
.loyalty__panel__offers__sku__list.loyalty__panel__offers__sku__product {
  text-align: center;
}

.loyalty__panel__offers__list-container {
  .loyalty__panel__offers__promo_desc {
    font-style: inherit;
    @include breakpoint($medium-up) {
      height: 120px;
    }
  }
  .loyalty__panel__offers__promo_title {
    @include breakpoint($medium-up) {
      height: 80px;
    }
  }
}

#loyalty__content {
  .outer-wrap {
    .mobile-hidden {
      @include breakpoint($large-up) {
        display: block;
      }
    }
  }
}

.account-section {
  a.back-link.pc-hidden {
    display: none;
  }
  &.account-section--loyalty .loyalty__panel__landing__logo img {
    @include breakpoint($tablet-only) {
      width: 100%;
    }
  }
  &__content {
    .non__loyalty__panel__join-now {
      input[type='checkbox'] {
        & ~ .label {
          @include swap_direction(padding-left, 0);
        }
      }
    }
  }
}

.site-header__menu__mobile-util-links.pc-hidden {
  @include breakpoint($small-down) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.js-loyalty__panel__offers__sku__slides {
  @include breakpoint($medium-up) {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

// Order Confirmation page
#confirmation-page {
  .checkout__content {
    .confirmation-panel__actions {
      margin-bottom: 5em;
    }
    .loyalty-join-panel__accept-loyalty-terms {
      input[type='checkbox'] {
        & ~ .label {
          @include swap_direction(padding-left, 0);
        }
      }
    }
  }
}

//GNAV
.sticky {
  .site-header {
    .icon--logo {
      @include breakpoint($large-up) {
        top: 5px !important;
      }
    }
    .site-header__utility {
      &-item__link {
        @include breakpoint($lyl-screen-small) {
          font-size: 8px;
        }
        @include breakpoint($lyl-screen-medium) {
          font-size: 9px;
        }
        @include breakpoint($lyl-screen-large) {
          font-size: 10px;
        }
      }
      &-item {
        .site-header-utility-not-signed-in,
        .site-header__account-menu-select {
          @include breakpoint($lyl-screen-small) {
            width: 80px;
            min-width: auto;
            padding-left: 0;
            font-size: 8px;
          }
          @include breakpoint($lyl-screen-medium) {
            width: 90px;
            min-width: auto;
            padding-left: 0;
            font-size: 9px;
          }
          @include breakpoint($lyl-screen-large) {
            width: 100px;
            min-width: auto;
            font-size: 10px;
          }
        }
      }
    }
    .menu__item--category-wrapper {
      @include breakpoint($lyl-screen-small) {
        font-size: 8px;
      }
      @include breakpoint($lyl-screen-medium) {
        font-size: 9px;
      }
      @include breakpoint($lyl-screen-large) {
        font-size: 10px;
      }
    }
  }
}

//Override admin bar behavior on dev/stage
body.toolbar-drawer {
  @include breakpoint($small-down) {
    padding-top: 0 !important;
  }
}
