.site-footer-pc {
  &__column-title {
    font-family: $font--lamer-text;
  }
}

.button {
  font-size: 11px;
  @include breakpoint($large-up) {
    font-size: 14px;
  }
}

.customer-service {
  &__collapsible-block {
    font-family: $font--lamer-text;
    .collapsible-block__content {
      font-family: $font--neue-haas-unica-pro;
    }
  }
  &__subtitle {
    font-family: $font--lamer-text;
  }
  &_columns_container {
    .registered_member,
    .check_signup {
      font-size: 13px;
    }
  }
}

.checkout-panel {
  &--return-user {
    .guest-btn {
      font-family: $font--neue-haas-unica-pro;
    }
  }
}

.site-email-signup,
.site-sms-signup {
  &__title {
    font-family: $font--lamer-text;
  }
  &__subtitle {
    font-style: normal;
  }
}

.content-block-formatter {
  &__headline {
    p {
      font-weight: bold;
    }
  }
}

body {
  .content-block {
    .content-block {
      &__typography {
        .text--1 {
          p {
            font-size: 13px;
          }
        }
        .heading--2 {
          font-size: 32px;
        }
        .heading {
          em {
            font-style: normal;
          }
        }
      }
    }
    &-collapsible {
      &__headline {
        .heading {
          font-size: 32px;
        }
      }
    }
  }
  .text--1 {
    font-size: 13px;
  }
  .heading--2 {
    font-size: 32px;
  }
  #pr-rating-tab {
    header {
      .heading {
        font-size: 36px;
      }
    }
  }
  &.section-brand-story {
    .content-block {
      &__typography {
        .heading--1 {
          @include breakpoint($medium-up) {
            font-size: 43px;
            padding: 0;
          }
        }
      }
    }
  }
}

.product-subline,
.product__description-short,
.product-full__desc,
.product-sku-unit-price {
  font-style: normal;
}

.product {
  &__subline {
    font-size: 16px;
  }
}

#gift_card {
  .gift-card {
    &__subline {
      font-style: normal;
    }
  }
}

#payment-panel {
  .payment-info {
    .required-notice {
      font-style: normal;
    }
  }
}

.ff-quiz__inner {
  h2.ff-quiz {
    &__edit-headline {
      font-style: normal;
    }
  }
  .ff-quiz {
    &__slide--1 {
      .ff-quiz {
        &__question > li {
          font-style: normal;
        }
      }
    }
  }
}

.ff-results {
  .ff-results {
    &__subheadline,
    &__primary_subheadline,
    &__secondary_subheadline {
      font-style: normal;
    }
  }
}

.product-inventory-status {
  &__item {
    font-style: normal;
  }
}

body.html .product-grid--theme-black {
  .product-brief--show-shade-size {
    .product-brief__footer {
      .button {
        font-size: 11px;
        @include breakpoint($large-up) {
          font-size: 14px;
        }
      }
    }
  }
}
