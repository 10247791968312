body {
  &#viewcart {
    .checkout {
      &__content {
        margin-bottom: 10px;
        .viewcart {
          &-header {
            border-bottom: 1px solid $color-light-gray;
            background-color: transparent;
            color: $color-dark-gray;
            padding: 0.4em 0;
            float: left;
            width: 100%;
            font-weight: 700;
            h2 {
              text-transform: uppercase;
              font-size: 24px;
              line-height: 36px;
              float: left;
            }
            .item-count {
              float: right;
              padding-top: 5px;
            }
          }
          &-buttons-panel {
            .continue {
              &-checkout-btns {
                width: 100%;
              }
            }
          }
        }
        .delivery {
          &_options__content {
            margin: 30px 11px 0;
            padding-bottom: 30px;
          }
          &-date-wrapper {
            display: block;
            margin-bottom: 20px;
          }
        }
      }
      &__sidebar {
        .panel {
          &.viewcart {
            &-buttons-panel {
              border-bottom: none;
              padding: 0;
              margin: 0;
            }
          }
        }
        .need-help-panel,
        .tab-switcher {
          display: none;
        }
      }
    }
  }
}

.bottom-viewcart-buttons {
  @include sticky__button();
  .continue-buttons {
    .text_link {
      display: none;
    }
  }
}

.viewcart-panel {
  .viewcart-buttons-panel {
    display: none;
  }
}

.recommended-products-panel {
  display: none;
}
/* checkout signin */
#index {
  .checkout {
    .sign-in-panel {
      .new-or-returning-radios {
        fieldset {
          margin-bottom: 10px;
        }
      }
      .checkout {
        &__new-account,
        &__return-user {
          padding-bottom: 30px;
          border: 0;
        }
      }
      input[type='submit'] {
        width: 100%;
      }
    }
    #shipping-panel {
      label,
      select {
        margin-bottom: 1.5em;
      }
      input[type='submit'] {
        width: 100%;
      }
      .gift-options-display {
        > a {
          &.edit {
            background: 0 0;
            color: $color-black;
            padding: 0;
            border: 0;
            text-decoration: underline;
          }
        }
      }
    }
    #registration-panel {
      border-top: 1px solid $color-light-gray;
      padding: 20px 0;
      .registration {
        &-form-container {
          .form-item {
            .label-class {
              display: block;
              margin: 15px 0 5px 0;
            }
            input[type='password'] {
              margin: 0 0 10px 0;
            }
          }
          .continue-button-wrapper {
            text-align: left;
            margin-top: 20px;
          }
        }
      }
      &.active {
        border-bottom: 1px solid $color-light-gray;
      }
    }
    .checkout__sidebar {
      display: block;
      float: left;
      margin-top: 0;
      .viewcart {
        &-panel {
          display: block;
          margin: 0;
          border: 0;
          padding: 0;
        }
        &-header {
          border-bottom: 1px solid $color-light-gray;
          padding: 0.4em 0;
          float: left;
          width: 100%;
          .viewcart-panel {
            &__title {
              float: left;
              margin: 15px 0;
              width: 55%;
            }
          }
          .item-count {
            display: inline-block;
            margin: 23px 0;
            padding-left: 20%;
          }
        }
      }
      .cart-items {
        .cart-item {
          &__qty {
            width: 40%;
            padding-left: 25%;
          }
        }
      }
    }
    .finished {
      &.sign-in-panel {
        border-bottom: 1px solid $color-light-gray;
      }
      &.shipping-panel {
        & > header {
          padding: 20px 10px 10px 10px;
          border-bottom: 1px solid $color-light-gray;
          margin-bottom: 10px;
          .checkout {
            &__panel-title {
              display: inline-block;
            }
          }
          & > a {
            margin: 0 0 0 10px;
          }
        }
      }
    }
    .edit {
      &.shipping-panel {
        padding: 20px 0;
        & > header {
          padding: 0 10px;
          border-bottom: 1px solid $color-light-gray;
        }
        .sub-section {
          .edit-shipping-radio,
          .edit-billing-radio {
            margin: 20px 0;
            span {
              display: inline-block;
              width: 45%;
              label {
                display: inline;
              }
            }
          }
          .edit-billing-radio {
            label {
              display: block;
            }
          }
          &.new-address {
            .edit-shipping-radio {
              margin-top: 0;
            }
            .shipping-address-display {
              margin: 0 0 20px 0;
            }
          }
        }
        #ship_address_select {
          select {
            margin-bottom: 0;
          }
        }
        &.adpl {
          border-bottom: 1px solid $color-light-gray;
          .postal_code_container,
          .phone_requirements {
            margin-bottom: 15px;
          }
          .email-and-sms-promotions {
            padding-top: 15px;
            border-top: 1px solid $color-lightest-grey;
          }
        }
      }
    }
    .payment-panel {
      &.edit {
        padding: 30px 0;
        border-top: 1px solid $color-light-gray;
        float: left;
        .field-are-required {
          position: inherit;
        }
        .payment-container {
          input {
            float: left;
            margin-right: 5px;
          }
          label {
            width: 45%;
            float: left;
          }
          &.save-card {
            label {
              float: none;
            }
          }
          &__option {
            &--rakuten ~ label {
              width: 100%;
              margin-top: 10px;
            }
          }
        }
      }
      .continue-button-wrapper {
        &.rakuten {
          input {
            width: 100%;
            background: no-repeat center/100% url('/media/export/cms/checkout/rpay_btn.png');
            height: 80px;
            border: none;
            color: transparent;
          }
        }
      }
    }
  }
}

.wp-samples-landing-panel,
.wp-samples-panel {
  .samples-buttons {
    &.bottom {
      margin: 0 15px 15px 10px;
    }
  }
}
/* Samples page */
#samples {
  .samples {
    &-page {
      margin: 30px 15px 20px;
      .product {
        border: 0;
        margin-bottom: 0;
        &-subhead {
          width: auto;
          float: right;
          margin: 20px 0 10px 0;
        }
        .sample-select {
          &-button {
            clear: both;
            width: 100%;
            display: block;
            text-align: center;
          }
        }
      }
      .samples-panel {
        text-align: center;
        &__title {
          margin-bottom: 0;
        }
      }
      .remaining {
        .samples-panel {
          &__promo-text {
            display: inline-block;
            &.large {
              display: none;
            }
          }
        }
      }
    }
  }
}

.birth-month-container {
  .select-birth-month {
    width: 100%;
  }
  .checkout-birthday {
    &__descriptive-text {
      padding-top: 10px;
    }
    &__birth-month {
      margin-bottom: 10px;
    }
  }
}

.checkout-confirmation {
  &-page {
    .birthday-panel {
      padding-top: 10px;
    }
  }
}

#viewcart {
  .column {
    &.left {
      .engraving {
        .js-label-mode + label {
          width: 100px;
          text-align: center;
          padding-inline: 6px;
          line-height: 15px;
          margin-top: -55px;
          height: 18px;
          &::before {
            content: none;
          }
        }
      }
    }
  }
}
