@mixin btn-style() {
  height: auto;
  display: inline-block;
  background: $color-dark-gray;
  color: $color-white;
  border: 1px solid $color-dark-gray;
  border-radius: 4px;
  padding: 13px 15px 14px 15px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
  text-transform: uppercase;
}

@mixin btn-style-hover() {
  text-decoration: none;
  background: $color-green;
  color: $color-white;
  border-color: $color-green;
}

@mixin cart-items() {
  padding: 0.8em 0;
  float: left;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: bold;
}

@mixin checkout__title() {
  line-height: 36px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 0.5em;
  padding: 0;
  display: block;
  font-size: 20px;
  font-weight: bold;
}

@mixin sticky__button() {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1002;
  padding: 15px;
  background: $color-white;
  box-shadow: 0 -3px 6px $color-lightest-grey;
}

@mixin site_footer() {
  .site-footer {
    &__bottom {
      display: none;
    }
    &__bottom-condensed {
      display: block;
      @include breakpoint($medium-up) {
        .divider-pc {
          display: none;
        }
      }
    }
    &-pc {
      display: none;
    }
    .social-media-links,
    .basic-noderef,
    .menu-ref {
      display: none;
    }
  }
}

@mixin site_footer_checkout() {
  .site-footer {
    &__bottom {
      display: block;
      padding: 100px 0 100px;
      @include breakpoint($medium-portrait-only) {
        padding: 0;
      }
      @include breakpoint($large-up) {
        padding: 15px 0 30px;
      }
    }
    &__bottom-links {
      position: absolute;
      bottom: 120px;
      width: 100%;
      text-align: center;
      @include breakpoint($medium-up) {
        bottom: 40px;
      }
    }
    .site-footer {
      &__copyright {
        display: none;
      }
    }
    &__top-checkout {
      position: absolute;
      bottom: 260px;
      text-align: center;
      @include breakpoint($medium-up) {
        bottom: 60px;
      }
      width: 100%;
      .divider {
        display: none;
      }
    }
    &__bottom-checkout {
      position: absolute;
      bottom: 70px;
      left: 0;
      right: 0;
      @include breakpoint($medium-up) {
        bottom: 20px;
      }
    }
    &__accessibility {
      .accessibility_icon {
        @include breakpoint($medium-up) {
          border-right: 1.5px solid $color-sand;
          padding-right: 10px;
          height: 14px;
          &:last-child {
            border: none;
          }
        }
      }
      .checkout {
        &__footer--link {
          display: none !important;
        }
      }
    }
    &__country-chooser-wrapper {
      display: none;
    }
  }
  footer {
    &.footer {
      position: relative;
    }
  }
}
