.signup-tout {
  &-wrapper {
    .signup-tout {
      &-background-image {
        @include breakpoint($med-small-down) {
          min-height: 400px;
        }
        @include breakpoint($med-small-only) {
          min-height: 300px;
        }
      }
    }
  }
}

.account-profile {
  &__email {
    p {
      word-break: break-word;
    }
  }
}

#index,
#viewcart {
  .checkout {
    .cart {
      &-items {
        .cart-item {
          border-bottom: 1px solid $color-light-gray;
          margin: 10px 0;
          padding: 10px 0;
          &__thumb {
            width: 22%;
            float: left;
            margin-bottom: 1em;
          }
          &__desc {
            width: 66%;
            float: left;
            padding-right: 0;
            padding-left: 10px;
            a {
              text-decoration: none;
            }
          }
          &__remove-form {
            background-size: auto 100%;
          }
          &__qty {
            width: 25%;
            float: left;
            padding-left: 20%;
            clear: both;
            margin-top: 10px;
            @include breakpoint($med-small-up) {
              padding-left: 0;
              clear: initial;
            }
            select {
              width: 70%;
            }
          }
          &__qty-label {
            display: none;
          }
          &__total {
            width: 35%;
            text-align: center;
            float: right;
            font-weight: bold;
          }
          &__replenishment {
            clear: both;
            padding-left: 20%;
            .replenishment {
              &__description {
                display: none;
              }
            }
          }
        }
      }
      &-header {
        display: none;
      }
    }
    .order-summary {
      &-panel {
        margin: 30px 11px 0;
        border-bottom: 1px solid $color-light-gray;
        padding-bottom: 30px;
      }
      &__content {
        .label {
          float: left;
          margin-bottom: 1.5em;
          width: 75%;
          &.shipping {
            display: none;
          }
        }
        .value {
          float: right;
          width: 25%;
          text-align: right;
        }
        .total {
          border-top: 1px solid $color-light-gray;
          font-weight: 700;
          margin-bottom: 0;
          padding: 1em 0;
        }
      }
    }
    .offer-code-panel {
      padding-bottom: 30px;
      margin: 30px 11px 0;
      input[type='text'] {
        width: 54%;
        margin-bottom: 30px;
      }
      input[type='submit'] {
        width: 28%;
        vertical-align: top;
      }
    }
  }
  .bottom-viewcart-buttons {
    background: transparent;
    box-shadow: 0 0 0;
    .continue-checkout-btns,
    .continue-buttons {
      a {
        border: solid 1px $white;
      }
    }
  }
}

.continue-button {
  &-wrapper {
    @include sticky__button();
    background: transparent;
    box-shadow: 0 0 0;
    .btn {
      width: 98%;
      border: solid 1px $white;
      font-size: 15px;
      &:hover,
      &:focus {
        border: solid 1px $white;
      }
    }
  }
}

#payment-panel {
  .payment-edit-content {
    clear: both;
    .payment_gmo {
      span {
        text-transform: uppercase;
        display: inline-block;
        margin: 3% 10% 2% 0;
      }
      .card-number {
        margin-bottom: 10px;
        .label-content {
          display: none;
        }
      }
      .expiry {
        width: 100%;
        margin-bottom: 10px;
        .expires-month-wrapper,
        .expires-year-wrapper {
          margin: 0;
          label {
            display: block;
          }
        }
        select {
          width: 11em;
          display: inline-block;
        }
      }
      .cvv {
        width: 100%;
        input {
          width: 100%;
        }
        .label-content {
          display: none;
        }
      }
    }
    .payment-container {
      &.select-card {
        select {
          padding: 0 25px 0 12px;
          letter-spacing: 0;
        }
      }
    }
  }
}

.site-header {
  &__utility {
    &-left {
      &--pc {
        display: block;
        position: absolute;
        top: 5px;
        width: 45%;
        padding-right: 2px;
        text-align: center;
        li {
          display: none;
        }
      }
    }
    .gnav-util {
      &__content {
        &--signup {
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          width: 100%;
        }
      }
    }
    &-item--email {
      margin-#{$rdirection}: 8px;
    }
  }
  &__trustmark {
    &-wrapper {
      display: block;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  &__menu {
    .menu {
      &__item {
        &--lvl-1 {
          font-weight: normal;
        }
      }
    }
  }
  &__main {
    z-index: 1001;
  }
}

.site-footer {
  .menu-ref {
    &:first-child {
      width: 50%;
    }
    &:nth-child(3) {
      display: block;
      margin: 0 auto;
      width: 87%;
    }
  }
  .text-beauty {
    float: right;
  }
  .livechat {
    &__text {
      display: none;
    }
  }
}

.waitlist-form {
  &__submit {
    margin-top: 10px;
  }
}

.scroll-animation {
  &.transition {
    overflow: hidden;
  }
}

.sticky__live-chat {
  &__mobile {
    position: fixed;
    bottom: 30%;
    right: 3px;
    text-align: center;
    z-index: 1;
    &--link {
      border: 1px solid $color-gray;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: block;
      background: $color-white;
      img {
        vertical-align: top;
        margin-top: 5px;
      }
    }
  }
}
