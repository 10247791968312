.address-book-page {
  &__overlay {
    .buttons {
      .address-overlay_submit {
        width: 100%;
      }
    }
    input[type='button'] {
      width: 100%;
    }
  }
}

.profile-page {
  &__content {
    .optional-info {
      .form-item {
        float: left;
        width: 33%;
        padding: 0 5px;
        p {
          margin: 0;
        }
      }
    }
  }
}

.replenishment {
  &-detail-page,
  &-page {
    .product {
      &__img {
        float: none;
        width: auto;
        height: auto;
        margin-right: 0;
      }
      &__frequency {
        .link {
          border: 0;
        }
      }
    }
    .section-header {
      .account-breadcrumb-wrapper {
        .back-link {
          .back-label {
            margin-left: 20px;
          }
        }
      }
    }
  }
  &-detail-page {
    &__content {
      .payment-info {
        border-bottom: 1px solid $color-light-gray;
        &__submit {
          margin-bottom: 15px;
          float: right;
        }
      }
      .replenishment-controls {
        &__link,
        &__button {
          display: none;
        }
      }
    }
    &.account-section {
      margin-bottom: 10px;
    }
  }
  &-page {
    &__overlay {
      .replenish-cancel-confirm {
        padding: 0;
        &__link {
          text-decoration: none;
          font-size: 11px;
          padding: 13px 5px 14px 5px;
        }
      }
    }
  }
}

.payment-delete {
  &__info {
    margin-bottom: 10px;
  }
  &__link {
    &:nth-of-type(2n + 1) {
      float: left;
      margin-right: 20px;
    }
  }
}
