.messages {
  padding: 1em 1em 0 0;
  color: $color-error;
  background-color: transparent;
  border-width: 0;
  clear: both;
}

.error {
  color: $color-error;
}

.shipping-panel {
  .billing-address {
    &-form {
      > span {
        display: block;
        margin: 10px 0;
      }
    }
    &-from-fields {
      margin: 15px 0 0 0;
    }
  }
}

.links-panel {
  .link {
    border-bottom: none;
    a {
      border-bottom: 1px solid $color-dark-gray;
      text-decoration: none;
    }
  }
}

.btn {
  @include btn-style();
  &:hover,
  &:focus {
    @include btn-style-hover();
  }
  .continue-buttons & {
    width: 100%;
    margin-bottom: 0;
    height: auto;
    line-height: 1;
    @include breakpoint($landscape-up) {
      width: auto;
      font-size: 14px;
    }
  }
}

.bottom-viewcart-buttons {
  .viewcart-buttons-panel {
    @include breakpoint($landscape-up) {
      margin-bottom: 35px;
    }
    &.viewcart-buttons-empty {
      .continue-buttons {
        display: none;
        @include breakpoint($small-down) {
          display: block;
        }
      }
    }
    .continue-buttons {
      display: block;
    }
  }
}

.viewcart,
#index,
#confirm {
  .checkout {
    &__sidebar {
      .panel {
        padding: 1em 0;
        margin: 0 1em;
        border-bottom: 1px solid $color-light-gray;
        &.links-panel {
          border-bottom: none;
          display: block;
        }
        header {
          padding: 0;
        }
      }
      .tab-switcher {
        margin-top: 20px;
      }
    }
  }
}

.checkout {
  &__panel-title,
  &__subtitle {
    @include checkout__title();
    &.giftwrap {
      margin: 25px 0 0;
      line-height: 20px;
    }
  }
  &__content {
    width: 100%;
    @include breakpoint($landscape-up) {
      width: 66.6666666667%;
      height: auto;
    }
    section {
      &.payment,
      &.registration,
      &.shipping {
        &-panel {
          &.collapsed {
            display: block;
            float: left;
            width: 100%;
            margin-top: 15px;
            border-bottom: 1px solid $color-lightest-grey;
          }
        }
      }
    }
  }
  &__sidebar {
    width: 100%;
    margin-top: 40%;
    @include breakpoint($landscape-up) {
      width: 33.3333333333%;
      margin-top: 0;
    }
    @include breakpoint($medium-up) {
      margin-top: 5%;
    }
  }
  &__panel-title {
    .toggle-icon {
      float: right;
      margin-top: 5px;
      .offer-code-panel & {
        margin-top: -33px;
        .viewcart & {
          margin-right: 42px;
        }
      }
      &.icon {
        &--minus {
          @include icon('minus');
          margin-top: 0px;
          .offer-code-panel & {
            margin-top: -38px;
            .viewcart & {
              margin-right: 42px;
            }
          }
        }
      }
    }
  }
}

.generic-helplinks-overlay {
  &-shippinginfo,
  &-returns-policy,
  &-purchase-policy {
    .customer-service {
      margin: 0;
      padding: 0;
      &__navigation,
      &__contact_info,
      &__title,
      &__menu-mob,
      .basic-responsive {
        display: none;
      }
      &__content {
        width: 100%;
      }
    }
    #cboxLoadedContent {
      max-height: 500px;
    }
  }
}

.address-book-page {
  &__overlay {
    .address {
      &-overlay_header {
        text-align: left;
        margin-bottom: 15px;
        border-bottom: 1px solid $color-lighter-gray;
        padding-bottom: 10px;
      }
      &-form {
        .phones_option {
          .form-item {
            width: 100%;
            &.radio {
              margin-bottom: 5px;
              .field {
                float: left;
                margin-right: 20px;
                @include breakpoint($medium-down) {
                  float: none;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}

#index {
  .checkout {
    .mobile-breadcrumb {
      display: none;
    }
    .sign-in-panel {
      border-bottom: 1px solid $color-lightest-grey;
      padding: 20px 0;
      .messages {
        margin: 10px 0;
      }
      .column {
        @include breakpoint($medium-up) {
          width: 50%;
          float: left;
          padding: 0 10px 20px 10px;
          input[type='email'],
          input[type='password'] {
            width: 100%;
          }
        }
      }
      .signin-panel {
        &__title {
          margin-bottom: 0;
          line-height: 20px;
        }
      }
    }
    .shipping-panel {
      .gift-options {
        &__content {
          .card-message {
            input[type='text'] {
              &::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: $color-white;
              }
              &::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: $color-white;
                opacity: 1;
              }
              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $color-white;
              }
            }
            .gift-messages {
              input[type='text'] {
                margin-top: 0;
              }
              label {
                margin-bottom: 1.5em;
              }
              &:nth-child(4) {
                label {
                  margin-bottom: 5px;
                }
              }
            }
            input[data-error] {
              border: solid 1px $color-error;
            }
            &-from {
              margin-right: 0;
            }
          }
          .card-message-to,
          .card-message-from {
            @include breakpoint($landscape-up) {
              display: inline-block;
              width: 49%;
            }
            input[data-error] {
              border: solid 1px $color-error;
            }
          }
        }
      }
      .gift-options-display,
      .delivery-options-display,
      .gift-message-h,
      .shipping-edit-address-content {
        &__title {
          margin-bottom: 0;
          line-height: 24px;
        }
      }
    }
    &__panel {
      &-title {
        .edit {
          font-size: 20px;
          text-decoration: none;
        }
      }
    }
    &__sidebar {
      .viewcart-panel {
        margin: 0;
        .edit {
          &.btn {
            color: $color-dark-gray;
            background: transparent;
            border: none;
            padding: 5px 0 0 10px;
            border-radius: initial;
            margin-bottom: 10px;
            text-decoration: underline;
            &:hover {
              color: $color-green;
              text-decoration: none;
              border-bottom: none;
            }
          }
        }
      }
      .order-summary {
        &-panel {
          .value {
            padding-right: 0;
          }
        }
      }
    }
  }
  &.active-panel-signin {
    .sign-in-panel {
      float: left;
    }
  }
  .account-section {
    .account-overview {
      &__profile,
      &__order {
        @include breakpoint($medium-portrait-only) {
          width: 100%;
          margin-left: 0;
          text-align: center;
        }
        @include breakpoint($medium-landscape-only) {
          width: 47%;
        }
      }
      &__profile {
        span,
        p {
          @include breakpoint($medium-portrait-only) {
            float: none;
            display: inline-block;
          }
        }
      }
    }
  }
}

.signup-tout {
  &-wrapper {
    .signup-tout {
      &-content {
        overflow: visible;
      }
    }
  }
}
/* sidebar tab pane */
.tab-bar {
  display: none;
}

html {
  &.js {
    body {
      &#viewcart {
        .tab-pane {
          opacity: 1;
          height: auto;
          header {
            display: block;
          }
        }
      }
      &#foundation_finder {
        padding-top: 0;
      }
    }
  }
}

.promo-panel {
  .offer-banner {
    &-formatter {
      height: auto;
      border: 0;
    }
    &-item {
      @include breakpoint($large-up) {
        width: 100%;
      }
    }
  }
}

.loading {
  background: url(/media/dev/global/icon_loading.gif) no-repeat center center $color-white;
}

.loading * {
  opacity: 0.8;
  pointer-events: none;
}

.foundation-finder-page {
  .site-content {
    .loading {
      background: transparent;
    }
    .loading *:not(.ff-quiz__shadebox):not(.ff-quiz__button) {
      opacity: 1;
    }
  }
}

.account-setting {
  &__section {
    .account-settings {
      &__container {
        .section {
          &-content {
            .account-info {
              .label {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}

.checkout-confirmation {
  &-page {
    &#confirmation-page {
      #confirmation-panel {
        .content {
          p:nth-of-type(1) {
            width: 100%;
            background-color: $color-white-smoke;
            padding: 15px;
            margin: auto;
            margin-bottom: 20px;
            font-weight: bold;
            text-align: center;
            a {
              color: $color-green;
              text-decoration: none;
            }
          }
        }
        h2 {
          @include checkout__title();
          text-align: center;
        }
        .confirmation-panel {
          &__actions {
            margin-left: 0;
            .print-buttons,
            .return-link-container {
              width: 48%;
              @include breakpoint($small-down) {
                width: 100%;
                margin-bottom: 10px;
              }
              a {
                width: 100%;
              }
            }
            .print-buttons {
              float: left;
            }
            .return-link-container {
              float: right;
            }
            .button {
              @include btn-style();
              font-size: 15px;
              background: $color-dark-gray !important;
              padding: 13px 15px 14px 15px !important;
              line-height: 1 !important;
              &:hover,
              &:focus {
                text-decoration: none;
                background: $color-green !important;
                color: $color-white !important;
                border-color: $color-green;
              }
            }
          }
        }
      }
      .sign-in {
        &-confirmation-panel {
          &__content {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.esearch-nav {
  &__animation-wrapper {
    .typeahead-wrapper {
      .see-results {
        &--lower {
          padding: 5px 0 0;
          .search-results {
            .search-results {
              &--upper {
                display: none;
              }
            }
          }
        }
        &--upper {
          .search-results {
            display: inline-block;
            padding: 0;
            .search-results {
              &--lower {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.active-panel {
  &-shipping,
  &-payment,
  &-samples,
  &-signin {
    @include site_footer();
    .mobile-checkout-back {
      margin-left: 15px;
      display: block;
      top: 12px;
      position: relative;
      cursor: pointer;
      .icon {
        height: 27px;
        width: 16.8px;
        font-size: 27px;
      }
    }
    @include site_footer_checkout();
    .site-header {
      &.sticky {
        .site-header {
          &__utility-left {
            &.pc-hidden {
              @include breakpoint($medium-portrait-only) {
                display: block;
              }
              @include breakpoint($landscape-up) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &-cart {
    @include site_footer();
    @include site_footer_checkout();
  }
}

select::-ms-expand {
  display: none;
}

.samples-page {
  .edit-cart {
    display: none;
  }
  .viewcart-buttons {
    &-panel {
      .continue-buttons {
        float: right;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        margin-bottom: 10px;
        @include breakpoint($landscape-up) {
          position: static;
        }
        @include breakpoint($medium-portrait-only) {
          display: block;
          text-align: center;
        }
        .continue-checkout {
          text-transform: uppercase;
          width: 90%;
          font-size: 15px;
          border: 1px solid $color-white;
          @include breakpoint($landscape-up) {
            width: auto;
          }
        }
      }
    }
  }
  .samples-panel {
    @include breakpoint($tablet-only) {
      padding: 30px;
      .product-list {
        .product {
          min-height: 470px;
          .details {
            .skus {
              bottom: 0;
              right: 28%;
              position: absolute;
              @include breakpoint($medium-landscape-only) {
                right: 37%;
              }
            }
          }
        }
      }
    }
  }
}

.product-breadcrumb {
  opacity: 0.8;
}

.product-quickshop {
  &__summary {
    clear: both;
  }
  &__price-wrapper {
    height: auto;
  }
}

.product-full {
  &__accordion {
    &__container {
      clear: both;
    }
  }
  &__mobile-panels {
    clear: both;
  }
  &__rating {
    .p-w-r {
      .pr-no-reviews {
        .pr-snippet-stars-reco-inline {
          .pr-snippet-write-review-link {
            margin-right: 0;
          }
        }
        .pr-snippet-stars-png {
          .pr-star-v4,
          .pr-star-v4-0-filled {
            margin-right: 0;
          }
        }
        .pr-snippet-write-review-link {
          padding: 0;
        }
      }
    }
  }
  &__details {
    .p-w-r {
      .pr-accessibly-hidden-file-input {
        & + label {
          span {
            &:after {
              content: '';
            }
          }
        }
      }
      .pr-btn-add-tag {
        a {
          &:after {
            content: '';
          }
        }
      }
    }
    .pr-war {
      .pr-header {
        &-title {
          margin-bottom: 0;
          &:after {
            content: '* = 必須';
            display: block;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-main-header-search-sort {
          .pr-rd-review-header-sorts {
            .pr-rd-sort-group {
              &:before {
                content: '並び替え: ';
              }
            }
          }
        }
        .pr-rd-review-header-contents {
          &:before {
            content: '絞り込み:';
          }
          .pr-multiselect {
            display: inline-block;
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'はい';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'いいえ';
              }
            }
          }
        }
        .pr-rd-header {
          .pr-rd-review-headline {
            font-style: normal;
          }
        }
      }
    }
    .pr-media-modal {
      .pr-cross-icon {
        &__circle {
          display: none;
        }
      }
      .button {
        &__close {
          &:hover {
            .pr-cross-icon {
              &__line {
                stroke: $white;
              }
            }
          }
        }
      }
    }
    .pr-review-snapshot-block-container {
      .pr-snippet-write-review-link {
        @include breakpoint($medium-up) {
          width: 100%;
        }
      }
    }
  }
}

.spp_reviews {
  .js-mpp-summary-snippet {
    .p-w-r {
      .pr-snippet {
        .pr-snippet-stars-png {
          text-align: center;
          .pr-snippet-rating-decimal {
            font-size: 24px;
            &:after {
              content: ' ／ 5';
            }
            &:before {
              content: '総合評価 ';
            }
          }
        }
      }
    }
  }
}

.terms-conditions-overlay {
  .customer-service {
    @include breakpoint($landscape-up) {
      padding: 0;
    }
    &__content {
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
  }
}

.cs-contact-info {
  .terms-conditions-overlay & {
    display: none;
  }
  &__livechat {
    display: none;
  }
  &__email {
    .cs-contact-info {
      &__description {
        a.link {
          @include breakpoint($landscape-up) {
            font-size: 13px;
          }
        }
      }
    }
  }
  @include breakpoint($landscape-up) {
    width: 44%;
    margin-right: 10px;
  }
}

.esearch {
  &__recommendation-list {
    &__list {
      .recommendation-list-item {
        padding: 10px 0 10px 0;
        &__link {
          font-size: 14px;
        }
      }
    }
  }
  &__product {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.store-locator-section {
  &__list-item {
    &--right {
      @include breakpoint($landscape-up) {
        width: 23%;
      }
    }
    &--left {
      @include breakpoint($small-down) {
        width: 40%;
      }
    }
    &--middle {
      width: 60%;
      @include breakpoint($landscape-up) {
        width: 67%;
      }
    }
  }
}

.search-wrapper {
  &__summary {
    .no-term {
      display: none;
    }
  }
}

#cboxContent {
  .waitlist-form {
    &__header {
      font-size: 30px;
    }
    &__success {
      margin-top: 17%;
      &__header {
        font-size: 24px;
      }
    }
  }
}
/* delamer logo styling updates & header styling */
.site-header {
  .icon--logo {
    height: 25px;
    width: 140px;
    @include breakpoint($large-up) {
      width: 240px;
      height: 36.5px;
    }
  }
  &.sticky {
    .site-header {
      .icon--logo {
        @include breakpoint($large-up) {
          width: 150px;
          height: 25px;
          top: -11px;
        }
      }
    }
  }
  &__utility-left {
    li {
      border-right: none;
    }
  }
}

.adpl {
  .select {
    .default-shipping {
      label {
        display: block;
      }
    }
  }
}

.payment {
  &__delete {
    &--data {
      margin: 0;
    }
  }
}

.samples-panel {
  li {
    &.deactivate {
      .sample-select-button {
        &.btn {
          background-color: $color-medium-gray;
          border-color: $color-medium-gray;
          cursor: default;
        }
      }
    }
  }
}

.favorites {
  &__list {
    .coming_soon_stock_msg {
      .product-info {
        &__link {
          display: block;
          margin-top: 5px;
        }
      }
      .button-comingsoon,
      .button-email {
        display: inline-block;
        margin-top: 0;
      }
    }
  }
}

#shipping-panel {
  .click-and-collect-method-select,
  .click_and_collect-standard-shipping {
    label {
      display: block;
    }
  }
}

.store-locator__map {
  &-recentering-search-prompt-button {
    display: none;
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-size: 15px !important;
    font-family: $font--neue-haas-unica-pro;
    color: $color-dark-gray !important;
    @include breakpoint($medium-up) {
      font-size: 13px !important;
    }
  }
}
