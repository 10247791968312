// BOGO OfferCode Styles
.bogo-offer {
  .cart-item {
    &__replenishment,
    &__remove-form,
    &__add-to-favorites {
      display: none;
    }
  }
}
