.appt-book {
  margin: 0px 20px;
  .location-submit {
    width: 310px;
  }
  .service-select {
    .service {
      .selection-bar {
        .selection-time {
          width: 40%;
        }
        .selection-selector {
          width: 60%;
        }
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        overflow: visible;
        .registration__email-list-text {
          font-family: 'Neue Haas Unica Pro';
          font-size: 12px;
          font-variant: normal;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 18px;
        }
        .registration__email-list {
          height: auto;
        }
        input {
          &.appt-book-mobile {
            width: 100%;
          }
        }
      }
      .appointment-select {
        .date-picker {
          .pikaday-container {
            .pika-single {
              .pika-lendar {
                table.pika-table {
                  th {
                    padding: 10px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.counters {
    .appt-book-location__signin-text {
      p {
        width: 100%;
        text-align: center;
        margin: 2em auto;
      }
    }
  }
  .confirmation-container {
    .location {
      h4 {
        line-height: 35px !important;
      }
      span.location-name {
        padding-top: 6px !important;
      }
    }
  }
}

.appt-book-overlay {
  &.appt-book-book-conflict-overlay,
  &.appt-book-services-overlay,
  &.appt-book-services-unavailabe-overlay {
    .overlay-content-container {
      height: auto;
      .overlay-content {
        height: auto;
      }
    }
  }
}
